import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

import {theme} from '../../../themes/default-theme';
import {Text} from '../../typography'
import RespImg from '../../ui/RespImg';

const Container = styled.div`
  ${theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
  }
  ${props => props.autoWidth && `
    justify-content: center;
  `}
`

const ImgContainer = styled.div`
  max-height: 500px;
  background-color: black;
  ${props => props.autoWidth && `
    background-color: white;
  `}
  position: relative;
  overflow: hidden;

  ${theme.breakpoints.up('md')} {
    max-height: 100%;
    min-height: 100%;
    width: 52.8571%;
  }

  picture {

      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      ${props => props.autoWidth && `
        position: static
      `}
  }

  img {
      width: 100%;
      height: auto;
      margin: 0 auto;
      display: block;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${props => props.autoWidth && `
        position: static;
        top: 0;
        left: 0;
        transform: translate(0);
        margin: 0;
      `}
      ${theme.breakpoints.up('md')} {
        width: 100%;
        height: 100%;
      }

  }
`

const ImgRatioContainer = styled.div`

  height: 0;
  padding-top: 67.56756%;
  position: relative;
  min-height: 100%;

  ${props => props.autoWidth && `
    height: 100%;
    padding-top: 0;
  `}

  img {
    ${props => props.cover && `
      min-height: 100%;
      width: auto;
      max-width: initial;
    `}
  }
`

const ContentContainer = styled.div`
  background-color: ${theme.palette.white};
  padding: 18px;

  ${theme.breakpoints.up('md')} {
    padding: 50px;
    width: 47.1428%;

    ${props => props.autoWidth && `
      width: 100%;
    `}
  }

  ${theme.breakpoints.up('lg')} {
    padding: 90px;
  }
`

const TextContent = styled.div`
  text-align: left;
`
  
const GalleryOverlay = ({
  type,
  title,
  text,
  image,
  imgCover,
  autoWidth
}) => {
  return (
  	<Container autoWidth={autoWidth}>
      <ImgContainer autoWidth={autoWidth}>
        <ImgRatioContainer autoWidth={autoWidth} cover={imgCover ? 1 : 0}>
          <RespImg 
            {...image}
          />
        </ImgRatioContainer>
      </ImgContainer>
      <ContentContainer autoWidth={autoWidth}>
        <Text variant="subtitle1" colour="skyBlue">{type}</Text>
        <Text variant="h3">{title}</Text>
        {text &&
          <TextContent>
            <Text variant="body1">{ReactHtmlParser(text)}</Text>
          </TextContent>
        }
      </ContentContainer>
    </Container>
  )
}
  
GalleryOverlay.propTypes = {
  type: propTypes.string,
  title: propTypes.string,
  text: propTypes.string,
  image: propTypes.object,
  imgCover: propTypes.bool,
}
  
GalleryOverlay.defaultProps = {
  imgCover: false
}
  
export default GalleryOverlay