import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import topoPng from '../../../assets/topo-block-blue.png';

import {theme} from '../../../themes/default-theme';
import Text from '../../typography';
import {Cta, RespImg} from '../../ui';
import IconLink from '../../ui/IconLink';
import LazyVideo from '../LazyVideo';

const Wrapper = styled.div`
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  
  ${theme.breakpoints.up('md')} {
    margin: 0 auto;
  }

`

const Container = styled.div`
  max-width: ${theme.containers.main.width};
  margin: 0 auto;
  padding: 0 ${theme.containers.main.gutter};
  position: relative;
  z-index: 2;
`

const Row = styled.div`
  ${theme.breakpoints.up('lg')} {
    display: flex;
    align-items:center;
    flex-direction: ${props => props.imagePosition === 'right' ? 'row-reverse' : 'row'};
  }
`

const ImageCol = styled.div`
    margin-bottom: 20px;
    ${theme.breakpoints.up('lg')} {  
      width: 52%;
      margin-bottom: 0;
    }  
  }
`
const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    ${theme.breakpoints.up('lg')} {  
      width: 52%;
      margin-bottom: 0;
    }

    video {
      width: 100%;
      height: 100%;
    }
 
`

const TextContainer = styled.div`
  margin: 20px 0;    
`

const ContentCol = styled.div`
  text-align:center;
  ${theme.breakpoints.up('lg')} {
    margin-top: 20px;
    width: 50%;
    ${props => props.imagePosition === 'right' ? 'padding-right: 24px' : 'padding-left: 24px'};
  }
  ${theme.breakpoints.up('lg')} {
    margin-top: 33px;
    width: 48%;
    ${props => props.imagePosition === 'right' ? 'padding-right: 70px' : 'padding-left: 70px'};
  }
`

const ContentBlock = styled.div`
  width:100%;
  text-align:center;
  max-width:495px;
  margin:auto;
  ${theme.breakpoints.up('lg')} {    
    ${props => props.imagePosition === 'right' ? 'float:left' : 'float:right'}; 
  }
  a {
    width:auto;
  }
  .MuiTypography-h3,
  h3 {
    font-weight:800;
  }
`

const IconLinkContainer = styled.div`
  margin-bottom: 20px;
`

const ImageText = (props) => {
  const {
    anchorId,
    background,
    preTitle,
    title,
    text,
    imagePosition,
    link,
    cta,
    image,
    video,
    hTag
  } = props;
  
  console.log('iwt', props);
  return (
    <Wrapper backgroundColor={background} id={anchorId}>
      <Container>
        <Row imagePosition={imagePosition}>
          {image && !video ?
            <ImageCol>
                <RespImg 
                  {...image}
                  _console={true}
                />
              
            </ImageCol>
          : video &&
              <VideoContainer>
                <LazyVideo 
                  attributes={{
                    muted:"true", 
                    autoPlay:"true", 
                    loop:"true", 
                    playsInline:"true"
                  }}>
                  <source src={video} />
                </LazyVideo>
              </VideoContainer>
        }
          <ContentCol imagePosition={imagePosition}>
            <ContentBlock imagePosition={imagePosition}>
              {preTitle &&
                <Text weight={700} variant="subtitle" colour="vividBlue" uppercase align="center">{preTitle}</Text>
              }
              <Text variant="h3" element={hTag ? hTag : 'h3'} colour={background === 'black' ? "white" : "charcoal"} uppercase align="center">{title}</Text>
                <TextContainer>
                  <Text variant="body1" colour={background === 'black' ? `white` : `charcoal` } align="center">{ReactHtmlParser(text)}</Text>
                </TextContainer>
                {(link && link.title) &&
                  <IconLinkContainer>
                    <IconLink colour={background === 'black' ? 'white' : 'black'} link={link} />
                  </IconLinkContainer>
                }
                {(cta && cta.title) && 
                  <Cta primary href={cta.href || '#'}>
                    {cta.title}
                  </Cta>
                }
            </ContentBlock>
          </ContentCol>
        </Row>
      </Container>
    </Wrapper>
  )
}
  
ImageText.propTypes = {
  anchorId: propTypes.string,
  background: propTypes.string,
  title: propTypes.string,
  text: propTypes.string,
  imagePosition: propTypes.string,
  link: propTypes.object,
  cta: propTypes.object,
  image: propTypes.object,
  video: propTypes.string
}
  
ImageText.defaultProps = {
  anchorId: null,
  background: null,
  title: null,
  text: null,
  imagePosition: 'left',
  link: null,
  cta: null,
  image: null,
  video: null
}
  
export default ImageText